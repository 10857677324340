@import '../../styles/vars';
@import '../../styles/keyframes.scss';

.card {
    border-radius: 6px;
    background: #f8f8f9;
    padding: 2.5rem;

    animation-duration: 0.5s;
    opacity: 0;
    animation-fill-mode: forwards;

    &--animate {
        animation-name: fadeInUp, fadeIn;
        animation-timing-function: cubic-bezier(0, 0, 0.2, 1), cubic-bezier(0, 0, 0.2, 1);
    }
}

.excerpt {
    font-size: 16px;
}

.card__demos {
    display: block;
    width: 100%;
    position: relative;
    transition: opacity 0.4s ease;
    &:hover {
        opacity: 0.8;
    }
}

.card__desktop {
    width: 93.5%;
    border-radius: 4px;
    background: #eaeaea;
    aspect-ratio: 2 / 1;
    position: relative;
    img {
        aspect-ratio: 800 / 697;
        border-radius: 4px;
        width: 100%;
        background-color: lightgray 0px -46.781px / 100.668% 113.252% no-repeat;
        box-shadow: 0px 8px 50px 0px rgba(10, 22, 60, 0.08);
    }
}

.card__mobile {
    width: 18.75%;
    position: absolute;
    top: 12.57%;
    right: 0;
    aspect-ratio: 50 / 101;
    border-radius: 8px;
    img {
        aspect-ratio: 204 / 413;
        position: relative !important;
        border-radius: 8px;
        border: 4px solid $dark;
        background-color: lightgray 0px 2.788px / 100% 107.401% no-repeat;
    }
}

.backlog {
    border-radius: 1rem;
    border: 4px solid $dark;
    background: #eaeaea;
}

.card__info {
    padding-top: 2.5rem;
}

.card__name {
    display: block;
    padding-bottom: 0.75rem;
    color: $dark;
    transition: color 0.4s ease;
    &:hover {
        color: $excerpt;
    }
}

.card__excerpt {
    color: $excerpt;
    font-size: 16px;
    padding-bottom: 1.5rem;
    letter-spacing: -0.02em;
    max-width: 480px;
}

.card__link {
    color: $dark;
    svg {
        path {
            stroke: $dark;
        }
    }
    &:hover {
        color: #3b4563;
        svg {
            path {
                stroke: #3b4563;
            }
        }
    }
}

@media (max-width: 1480px) {
    .card {
        padding: 2rem;
    }

    .card__info {
        padding-top: 2rem;
    }
}

@media (max-width: 1200px) {
    .card__info {
        height: 176px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }
    .card__excerpt {
        font-size: 1rem;
        padding-bottom: 1rem;
    }
}

@media (max-width: 1024px) {
    .card {
        padding: 1.5rem;
    }

    .card__info {
        padding-top: 1.5rem;
    }

    .backlog {
        border-radius: 0.75rem;
    }
}

@media (max-width: 820px) {
    .card__info {
        height: auto;
    }
}

@media (max-width: 768px) {
    .card__name {
        font-size: 1.5rem;
    }

    .card__excerpt {
        max-width: 34rem;
        padding-bottom: 1.25rem;
    }
}

@media (max-width: 480px) {
    .card__name {
        padding-bottom: 0.5rem;
    }

    .card__excerpt {
        font-size: 0.9375rem;
    }
}

@media (max-width: 414px) {
    .card__desktop {
        aspect-ratio: 3 / 2;
    }

    .card__mobile {
        width: 24%;
        img {
            border: 3px solid $dark;
        }
    }

    .backlog {
        border-radius: 0.5rem;
        border: 3px solid $dark;
    }
}

@media (max-width: 376px) {
    .card {
        padding: 1.25rem;
    }

    .card__name {
        font-size: 1.25rem;
    }

    .card__excerpt {
        font-size: 0.875rem;
    }
}
