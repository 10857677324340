@import '../../styles/vars';

.faq__info {
    p {
        font-size: 16px;
        font-weight: 500;
        line-height: 144%;
        letter-spacing: -0.01em;
        opacity: 0;
        max-height: 0;
        background: $white;
        overflow: hidden;
        transition: all 0.4s ease;
        padding-top: 0;
        a {
            text-decoration: underline;
            font-weight: 600;
            transition: color 0.4s ease;
            &:hover {
                color: #6c738a;
            }
        }
    }
}

.faq__item {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    padding: 2rem 0;
    border-bottom: 1px solid #e4e5ea;
    cursor: pointer;
    transition: all 0.4s ease;

    p {
        width: 95%;
    }

    svg {
        display: block;
        width: 2rem;
        height: 2rem;
        flex-shrink: 0;
        transition: $anim;
        cursor: pointer;

        path {
            stroke: $dark;
            transition: stroke 0.4s ease;
        }
    }

    &:hover {
        .faq__point {
            color: #6c738a;
        }

        svg {
            path {
                stroke: #6c738a;
            }
        }
    }
}

.faq__point {
    font-size: 1.5rem;
    font-style: normal;
    font-weight: 600;
    line-height: 32px;
    letter-spacing: -0.05em;
    flex-grow: 1;
    transition: color 0.4s ease;
}

.active {
    .faq__info p {
        padding-top: 1rem;
        overflow: unset;
        max-height: 150px;
        opacity: 1;
        transition:
            max-height 0.4s ease,
            opacity 0.4s ease,
            padding-top 0.4s ease;
    }

    svg {
        transform: rotate(-180deg);
    }
}

@media (max-width: 1200px) {
    .faq__point {
        font-size: 1.25rem;
    }
    .faq__item {
        padding: 1.5rem 0;
    }
}
@media (max-width: 1024px) {
    .faq__info {
        width: 86%;
        p {
            width: 100%;
        }
    }
}

@media (max-width: 768px) {
    .faq__point {
        font-size: 1.125rem;
        line-height: 28px;
    }

    .faq__item {
        svg {
            width: 1.75rem;
            height: 1.75rem;
        }
    }

    .faq__info p {
        font-size: 1rem;
    }

    .active {
        .faq__info p {
            max-height: 200px;
        }
    }
}

@media (max-width: 576px) {
    .faq__item {
        padding: 1.25rem 0;
    }

    .faq__point {
        font-size: 1rem;
    }

    .faq__info p {
        font-size: 0.9375rem;
    }

    .active {
        .faq__info p {
            padding-top: 0.75rem;
        }
    }
}

@media (max-width: 480px) {
    .faq__info {
        width: 92%;
    }
}

@media (max-width: 414px) {
    .faq__item {
        svg {
            width: 1.25rem;
            height: 1.25rem;
        }
    }

    .faq__point {
        line-height: 1.25rem;
    }

    .faq__info p {
        font-size: 0.875rem;
    }

    .active {
        .faq__info p {
            max-height: 230px;
        }
    }
}

@media (max-width: 376px) {
    .active {
        .faq__info p {
            max-height: 260px;
        }
    }
}

@media (max-width: 340px) {
    .active {
        .faq__info p {
            max-height: 310px;
        }
    }
}
