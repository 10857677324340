@import '../../../styles/vars';
@import '../../../styles/keyframes.scss';

.bundle {
    max-height: 680px;
    height: 680px;
    h2 {
        max-width: 692px;
    }
    h2 svg {
        bottom: -6px !important;
    }
}

.heading {
    padding-bottom: 4rem;
    text-align: center;
}

.advantages__section {
    padding: 9rem 0;
    .heading {
        line-height: 70%;
        max-width: 1000px;
        margin: auto;
        .word__wrapper {
            line-height: 100%;
            span {
                transform: translateY(100%);
                line-height: 112%;
                vertical-align: middle;
            }
        }
        span {
            display: inline-block;
        }
    }
}

.word__wrapper {
    overflow-y: hidden;
    overflow-x: auto;
}

.animate__heading {
    .word__wrapper span {
        animation: $headingFade;
    }
}

@media (min-width: 1921px) {
    .bundle {
        height: 50rem;
        max-height: 50rem;
    }
}

@media (max-width: 1700px) {
    .advantages__section {
        padding: 7.5rem 0;
    }
}

@media (max-width: 1480px) {
    .bundle {
        height: 40vw;
    }

    .heading {
        padding-bottom: 3rem;
    }

    .advantages__section {
        padding: 6.5rem 0;
    }
}

@media (max-width: 1200px) {
    .heading {
        padding-bottom: 2.5rem;
    }

    .bundle {
        height: 42vw;
    }

    .advantages__section {
        padding: 5rem 0;
    }
}

@media (max-width: 1024px) {
    .bundle {
        height: 48vw;
        h2 {
            max-width: 460px;
        }
    }
}

@media (max-width: 820px) {
    .bundle {
        height: 51vw;
    }

    .advantages__section {
        .heading {
            text-align: left;
            margin: 0;
        }
    }
}

@media (max-width: 768px) {
    .bundle {
        height: auto;
        max-height: 100%;
    }

    .advantages__section {
        padding: 4rem 0;
    }
}

@media (max-width: 576px) {
    .advantages__section {
        padding: 3rem 0;
    }
}

@media (max-width: 480px) {
    .heading {
        padding-bottom: 2rem;
        font-size: 1.875rem;
    }
}
