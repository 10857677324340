@import '../../../styles/vars';
@import '../../../styles/keyframes.scss';

.section__heading {
    padding-bottom: 4rem;
}

.theme {
    padding: 4rem 4rem 0;
    background: #f8f8f9;
    border-radius: 6px;
    height: 33.44vw;
    max-height: 642px;
    display: flex;
    justify-content: space-between;
    overflow: hidden;
    margin-bottom: 4rem;

    animation-duration: 0.5s;
    opacity: 0;
    animation-fill-mode: forwards;

    &--animate {
        animation-name: fadeInUp, fadeIn;
        animation-timing-function: cubic-bezier(0, 0, 0.2, 1), cubic-bezier(0, 0, 0.2, 1);
    }
}

.section__left {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 43.81%;
    padding: 0 3.5625rem 4rem 0;
}

.logo__wrapper {
    width: 3.5rem;
    height: 3.5rem;
    img {
        aspect-ratio: 1 / 1;
        position: relative !important;
    }
}

.heading {
    color: $dark;
    padding-bottom: 1rem;
    display: block;
    transition: color 0.4s ease;
    &:hover {
        color: $excerpt;
    }
}

.buttons {
    display: flex;
    align-items: center;
    gap: 0.75rem;
}

.button {
    svg {
        transition: transform 0.4s ease-in-out;
        display: block;
    }
    &:hover {
        svg {
            transform: rotate(45deg);
        }
    }
}

.theme__right {
    display: block;
    width: 56.19%;
    position: relative;
    transition: opacity 0.4s ease;
    &:hover {
        opacity: 0.8;
    }
}

.theme__mobile {
    left: 0;
    width: 21.4%;
    position: absolute;
    bottom: -34px;
    z-index: 5;
    img {
        aspect-ratio: 204 / 413;
        position: relative !important;
        border-radius: 0.75rem;
        border: 5px solid $dark;
    }
}

.theme__desktop {
    right: 0;
    width: 85.56%;
    position: absolute;
    z-index: 0;
    border-radius: 4px;
    img {
        aspect-ratio: 160 / 131;
        position: relative !important;
        border-radius: 4px;
        box-shadow: 0px 8px 50px 0px rgba(10, 22, 60, 0.08);
    }
}

.theme__cards {
    display: flex;
    flex-wrap: wrap;
    column-gap: 2rem;
    row-gap: 2rem;
    li {
        width: calc((100% - 2rem) / 2);
    }
}

@media (max-width: 1480px) {
    .theme {
        height: 30.2vw;
        padding: 3rem 3rem 0;
        margin-bottom: 3rem;
    }

    .section__heading {
        padding-bottom: 3rem;
    }

    .section__left {
        width: 48%;
        padding: 0 3rem 3rem 0;
    }

    .theme__right {
        width: 52%;
    }

    .excerpt {
        padding-bottom: 1.5rem;
    }
}

@media (max-width: 1200px) {
    .theme {
        padding: 2.5rem 2.5rem 0;
        height: 33vw;
        margin-bottom: 2.5rem;
    }

    .section__heading {
        padding-bottom: 2.5rem;
    }

    .section__left {
        padding: 0 2.5rem 2.5rem 0;
        width: 50%;
    }

    .theme__right {
        width: 50%;
    }

    .heading {
        padding-bottom: 0.75rem;
    }
}

@media (max-width: 1024px) {
    .theme {
        height: 36vw;
        padding: 2rem 2rem 0;
        margin-bottom: 2rem;
    }

    .section__left {
        padding: 0 2rem 2rem 0;
        width: 59%;
    }

    .theme__right {
        width: 41%;
    }

    .theme__desktop {
        width: 100%;
        bottom: 0;
    }

    .theme__mobile {
        width: 27%;
        bottom: -5%;
        img {
            border-width: 4px;
        }
    }

    .excerpt {
        padding-bottom: 1rem;
    }

    .theme__cards {
        column-gap: 1.5rem;
        li {
            width: calc((100% - 1.5rem) / 2);
        }
    }
}

@media (max-width: 820px) {
    .theme {
        height: auto;
        flex-direction: column;
    }

    .section__left {
        height: auto;
        width: 79%;
        padding-bottom: 3rem;
    }

    .section__info {
        padding-top: 2rem;
    }

    .theme__right {
        width: 100%;
        flex-grow: 1;
        height: 35.37vw;
    }

    .theme__desktop {
        top: 0;
        width: 65%;
        right: 3rem;
        bottom: auto;
    }

    .theme__mobile {
        width: 24%;
        left: 3rem;
        bottom: -40%;
    }

    .theme__cards {
        gap: 2rem;
        flex-wrap: wrap;
        li {
            width: 100% !important;
        }
    }
}

@media (max-width: 768px) {
    .theme__mobile {
        bottom: -34%;
    }

    .section__heading {
        padding-bottom: 2rem;
    }
}

@media (max-width: 640px) {
    .section__left {
        width: 100%;
    }

    .theme__right {
        height: 38vw;
    }

    .theme__desktop {
        right: 2rem;
        width: 70%;
    }

    .theme__mobile {
        left: 2rem;
        bottom: -34%;
        width: 25%;
    }
}

@media (max-width: 576px) {
    .theme {
        padding: 1.5rem 1.5rem 0;
        margin-bottom: 1.5rem;
    }

    .section__left {
        padding: 0 1.5rem 3rem 0;
    }

    .section__info {
        padding-top: 1.5rem;
    }

    .theme__desktop {
        right: 1rem;
        width: 80%;
        top: 1rem;
    }

    .theme__right {
        height: 55vw;
    }

    .theme__mobile {
        left: 1rem;
        bottom: -20%;
        width: 28%;
    }

    .theme__cards {
        gap: 1.5rem;
    }
}

@media (max-width: 480px) {
    .section__left {
        padding: 0 0 3rem 0;
    }

    .section__heading {
        padding-bottom: 1.5rem;
    }

    .heading {
        padding-bottom: 0.5rem;
    }

    .buttons {
        flex-direction: column;
        align-items: flex-start;
        a,
        button {
            width: 100%;
        }
    }

    .theme__desktop {
        right: 0;
        width: 85%;
        bottom: 0;
        top: auto;
    }

    .theme__mobile {
        left: 0;
        width: 30%;
        bottom: -23%;
    }
}

@media (max-width: 414px) {
    .section__left {
        padding: 0 0 2.5rem 0;
    }

    .theme__desktop {
        bottom: auto;
        top: 0;
    }

    .theme__mobile {
        img {
            border-radius: 0.5rem;
            border: 3px solid $dark;
        }
    }
}

@media (max-width: 376px) {
    .theme {
        padding: 1.25rem 1.25rem 0;
    }

    .theme__desktop {
        bottom: auto;
        top: 0;
        width: 90%;
    }

    .theme__mobile {
        width: 33%;
        bottom: -25%;
    }

    .theme__right {
        height: 53vw;
    }
}
