@import '../../../styles/vars';
@import '../../../styles/keyframes.scss';

.section__container {
    position: relative;
    padding: 4rem 0 0 4rem;
    background: #f8f8f9;
    border-radius: 6px;
    height: 30.2vw;
    max-height: 36.25rem;
    margin-bottom: 9rem;
    display: flex;
    justify-content: space-between;
    overflow: hidden;
}

.section__left {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 43%;
    padding: 0 4rem 4rem 0;
}

.logo__wrapper {
    width: 3.5rem;
    height: 3.5rem;
    img {
        aspect-ratio: 1 / 1;
        position: relative !important;
    }
}

.heading {
    color: $dark;
    padding-bottom: 1rem;
}

.buttons {
    display: flex;
    align-items: center;
    gap: 1rem;
}

.section__info {
    a:hover,
    button:hover {
        svg {
            transform: rotate(0);
        }
    }
}

.support__img {
    position: absolute;
    width: 57.8%;
    height: auto;
    right: -44px;
    bottom: -55px;
    display: block;
    border-radius: 4px;
    background: $white;
    img {
        display: block;
        position: relative !important;
        aspect-ratio: 1036 / 571;
        border-radius: 4px;
        box-shadow: 0px 8px 50px 0px rgba(10, 22, 60, 0.08);
    }
}

@media (max-width: 1700px) {
    .section__container {
        margin-bottom: 7.5rem;
    }
}

@media (max-width: 1480px) {
    .section__container {
        padding: 3rem 0 0 3rem;
        margin-bottom: 6.5rem;
        height: 35vw;
    }

    .support__img {
        width: 62%;
        right: -11%;
        top: 3rem;
        bottom: auto;
    }

    .section__left {
        width: 47%;
        padding: 0 1.5rem 3rem 0;
    }
}

@media (max-width: 1200px) {
    .section__container {
        height: 36vw;
        padding: 2.5rem 0 0 2.5rem;
        margin-bottom: 5rem;
    }

    .section__left {
        padding: 0 2.5rem 2.5rem 0;
        width: 50%;
    }

    .support__img {
        width: 65%;
        top: 2.5rem;
        right: -18%;
    }

    .heading {
        padding-bottom: 0.75rem;
    }
}

@media (max-width: 1024px) {
    .section__container {
        padding: 2rem 0 0 2rem;
    }

    .section__left {
        padding: 0 2rem 2rem 0;
        width: 57%;
    }

    .support__img {
        width: 66%;
        top: 2rem;
        right: -22%;
    }
}

@media (max-width: 900px) {
    .section__container {
        height: 40vw;
    }

    .section__left {
        width: 60%;
    }

    .support__img {
        width: 72%;
        top: 2rem;
        right: -32%;
    }
}

@media (max-width: 820px) {
    .section__container {
        height: 70vw;
        padding: 2rem;
        display: flex;
        flex-direction: column;
        gap: 2rem;
    }

    .section__left {
        width: auto;
        padding: 0;
        max-width: 33rem;
        justify-content: flex-start;
        height: auto;
    }

    .section__info {
        padding-top: 2rem;
    }

    .support__img {
        width: 110%;
        position: static;
    }
}

@media (max-width: 768px) {
    .section__container {
        height: 78vw;
        margin-bottom: 4rem;
    }
}

@media (max-width: 640px) {
    .section__container {
        height: 85vw;
    }

    .support__img {
        width: 120%;
        bottom: -15%;
    }
}

@media (max-width: 576px) {
    .section__container {
        height: 600px;
        padding: 1.5rem;
        margin-bottom: 3rem;
    }

    .support__img {
        width: 576px;
    }

    .section__info {
        padding-top: 1.5rem;
        padding-right: 1.5rem;
    }
}

@media (max-width: 480px) {
    .section__container {
        height: 570px;
        gap: 1.5rem;
    }

    .section__info {
        padding-right: 0;
    }

    .heading {
        padding-bottom: 0.5rem;
    }
}

@media (max-width: 414px) {
    .section__container {
        height: 450px;
    }

    .support__img {
        width: 480px;
    }
}

@media (max-width: 376px) {
    .section__container {
        // height: 125vw;
        padding: 1.25rem;
    }

    .support__img {
        width: 420px;
    }
}
