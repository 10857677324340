@import '../../styles/vars';

.swiper {
    position: relative;
    overflow-y: visible;
}
.slide {
    margin-right: 2rem !important;
    background: $white;
}

.slide__content {
    width: 100%;
    position: relative;
    img {
        width: auto;
        height: auto;
        position: relative !important;
    }
}

.desktop {
    width: 37.8125vw !important;
    img {
        aspect-ratio: 121 / 80;
        position: relative !important;
        width: auto;
        height: auto;
        border-radius: 4px;
        box-shadow: 0px 8px 50px 0px rgba(10, 22, 60, 0.08);
        z-index: 4;
    }
}

.mobile {
    width: 11.719vw !important;
    img {
        aspect-ratio: 15 / 32;
        position: relative;
        width: auto;
        height: auto;
        border-radius: 16px;
        box-shadow: 0px 8px 50px 0px rgba(10, 22, 60, 0.08);
        z-index: 4;
    }
}

@media (max-width: 1480px) {
    .slide {
        margin-right: 1.75rem !important;
    }
}

@media (max-width: 1200px) {
    .slide {
        margin-right: 1.5rem !important;
    }
}

@media (max-width: 1024px) {
    .slide {
        margin-right: 1rem !important;
    }
}

@media (max-width: 1024px) {
    .slide__content {
        img {
            border-radius: 0.375rem;
        }
    }
}

@media (max-width: 768px) {
    .desktop {
        width: 47.25vw !important;
    }

    .mobile {
        width: 14.65vw !important;
    }
}

@media (max-width: 640px) {
    .slide {
        margin-right: 1rem !important;
    }
}

@media (max-width: 576px) {
    .desktop {
        width: 56.7vw !important;
    }

    .mobile {
        width: 17.58vw !important;
    }
}

@media (max-width: 414px) {
    .desktop {
        width: 68.04vw !important;
    }

    .mobile {
        width: 21.096vw !important;
    }

    .slide {
        margin-right: 0.75rem !important;
    }
}
