@import '../../../styles/vars';

.label {
    border-radius: 6.25rem;
    background: #ebecef;
    display: inline-block;
    width: fit-content;
    padding: 5px 11px;
    color: $excerpt;
    font-size: 13px;
    font-weight: 600;
    line-height: 120%;
    letter-spacing: -0.01em;
    text-transform: capitalize;
}
