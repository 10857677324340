@import '../../../styles/vars';

.link {
    font-size: 15px;
    font-weight: 600;
    line-height: 120%;
    letter-spacing: -0.03em;
    text-transform: capitalize;
    display: flex;
    align-items: center;
    width: fit-content;
    transition: $anim;
    padding-right: 20px;
    position: relative;

    svg {
        transition: $anim;
        will-change: transform;
        position: absolute;
        transform-origin: center;
        top: 50%;
        transform: translateY(-50%) rotate(0);
        right: 0;
    }

    &:hover {
        color: #3b4563;

        svg {
            transform: translateY(-50%) rotate(45deg);
        }
    }
}
