.faq__wrapper {
    max-width: 55rem;
    margin: 0 auto;
}

@media (max-width: 1200px) {
    .faq__wrapper {
        max-width: 100%;
        width: auto;
    }
}
