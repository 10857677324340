@import '../../../styles/vars';
@import '../../../styles/keyframes.scss';

.hero__section {
    padding: 6.5rem 0 9rem;
}

.hero__wrapper {
    max-width: 1000px;
    margin: 0 auto;
    text-align: center;
    padding-bottom: 4rem;
}

.heading {
    margin-bottom: 1.25rem;
    position: relative;
    line-height: 68%;
    .word__wrapper {
        line-height: 100%;
        span {
            transform: translateY(100%);
            line-height: 112%;
        }
    }
    span {
        display: inline-block;
    }
}

.word__wrapper {
    overflow-y: hidden;
    overflow-x: auto;
}

.animate__heading {
    .word__wrapper span {
        animation: $headingFade;
    }
}

.animate__excerpt {
    line-height: 100%;
    .word__wrapper span {
        animation: $excerptFade;
    }
}

.excerpt {
    max-width: 600px;
    margin: auto;
    margin-bottom: 2rem;
    padding-bottom: 0;
    line-height: 73%;
    .word__wrapper {
        line-height: 100%;
        span {
            transform: translateY(100%);
            line-height: 140%;
        }
    }
    span {
        display: inline-block;
    }
}

.buttons {
    display: flex;
    gap: 0.75rem;
    justify-content: center;
    align-items: center;
}

@media (max-width: 1700px) {
    .hero__section {
        padding: 6rem 0 7.5rem;
    }
}

@media (max-width: 1480px) {
    .hero__section {
        padding: 5rem 0 6.5rem;
    }

    .excerpt {
        max-width: 38rem;
    }

    .hero__wrapper {
        padding-bottom: 3rem;
        max-width: 750px;
    }

    .heading {
        margin-bottom: 0.75rem;
    }
}

@media (max-width: 1200px) {
    .hero__section {
        padding: 5rem 0;
    }

    .excerpt {
        margin-bottom: 1.5rem;
    }
}

@media (max-width: 1024px) {
    .hero__wrapper {
        max-width: 570px;
    }
}

@media (max-width: 768px) {
    .hero__section {
        padding: 4rem 0;
    }
}

@media (max-width: 640px) {
    .hero__wrapper {
        min-width: 100%;
        margin: auto;
        padding: 0 2rem 3rem;
    }
}

@media (max-width: 414px) {
    .hero__wrapper {
        padding: 0 1.5rem 3rem;
    }

    .buttons {
        flex-direction: column;
        div,
        button,
        a {
            width: 100% !important;
        }
    }
}

@media (max-width: 376px) {
    .hero__wrapper {
        padding: 0 1.25rem 3rem;
    }
}
